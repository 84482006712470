import React, { useContext, useEffect, useState } from "react";
import { Center, Image, VStack, KNOWDBContext } from "@know/ui";

declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage: (payload: string) => void;
    };
    authenticateToKNOW: () => void;
    isDirectlyOnRN: boolean;
  }
}

window.ReactNativeWebView =
  window.ReactNativeWebView || window.parent.ReactNativeWebView;

window.isDirectlyOnRN = !Boolean(
  window.authenticateToKNOW || window.parent.authenticateToKNOW
);

console.info(`window.isDirectlyOnRN: ${window.isDirectlyOnRN}`);

const callbacks: any = [];
function registerMessageEventListeners(listener) {
  callbacks.map((cb: any) => window.removeEventListener("message", cb));
  callbacks.push(listener);
  window.addEventListener("message", listener, false);
}

export const SDKLoginPage = ({ children }) => {
  const [config, setConfig] = useState<object | null>(null);
  const [token, setToken] = useState("");

  const [authenticated, setAuthenticated] = useState(false);

  const {
    isLoading: isLoadingAuth,
    currentUser,
    initializedLastCacheEnv,
    currentDBApi,
  } = useContext(KNOWDBContext);

  useEffect(() => {
    if (window.isDirectlyOnRN === false) {
      initializedLastCacheEnv();
      setAuthenticated(true);
    }
  }, [initializedLastCacheEnv, setAuthenticated]);

  useEffect(() => {
    if (
      !isLoadingAuth &&
      (!currentUser ||
        (currentUser as any)?.identifier !== (config as any)?.identifier)
    ) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          action: "getAuthToken",
        })
      );
    } else if (
      !isLoadingAuth &&
      currentUser &&
      (currentUser as any)?.identifier === (config as any)?.identifier
    ) {
      setAuthenticated(true);
    }
  }, [isLoadingAuth, currentUser, config]);

  useEffect(() => {
    if (currentDBApi && token && !authenticated) {
      (async () => {
        await currentDBApi.signInWithCustomToken(token);
      })();
    }
  }, [token, currentDBApi, authenticated]);

  useEffect(() => {
    registerMessageEventListeners(async (event: any) => {
      const { action, ...data } = event.data || {};
      if (action === "authenticateWithToken") {
        setToken(data.token);
      } else if (action === "setWebConfig") {
        const { config, identifier } = data;
        setConfig({
          ...config,
          identifier,
        });
        localStorage.setItem(
          "KNOWLastLoggedInEnvironment",
          JSON.stringify({
            [config.projectId]: {
              ...config,
              identifier,
            },
          })
        );
        initializedLastCacheEnv();
      }
    });
  }, [setToken, setConfig]);

  useEffect(() => {
    if (
      window.ReactNativeWebView &&
      window.isDirectlyOnRN === true &&
      !config &&
      !authenticated
    ) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          action: "getWebConfig",
        })
      );
    }
  }, [config, authenticated]);

  if (authenticated) {
    return children;
  }

  return (
    <Center
      flex={1}
      bg="primary.600"
      width={{ base: "100%" }}
      position="relative"
    >
      <VStack space={3} alignItems="center" justifyContent="center">
        <Image
          style={{ height: '100vh', width: '100vw', objectFit: 'cover'}}
          alt="Splash Screen - Redirecting screen"
          src={globalThis.splashScreenURL || '/assets/splash-screen.gif'}
        />
      </VStack>
    </Center>
  );
};
