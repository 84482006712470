import React from 'react';

import { SDKLoginPage } from './SDKLoginScreen';
import { CoursesScreen } from '@know/pages';
import { KNOWProvider, KNOWRoutes } from '@know/ui';
import appJson from './app.json';

globalThis.process = {
  ...globalThis?.process,
  env: {
    ...globalThis?.process?.env,
  }
}

const servingFrom = globalThis.servicingFrom || (globalThis?.parent as any).servingFrom || ''

export default function App() {
  const hasAccessToModule = () => {
    return true;
  };

  return (
    <KNOWProvider
      servingFrom={servingFrom}
      isDark={true}
      hasAccessToModule={hasAccessToModule}
      isSDK={true}
    > 
      <HomePage /> 
    </KNOWProvider>
  );
}

const HomePage = () => {
  const version = appJson.expo.version || '1.0.0'
  return <SDKLoginPage>
        <CoursesScreen />
        <KNOWRoutes version={version} showHeader={false} />
    </SDKLoginPage>
};
